import React, { useState } from "react"
import { Link } from "gatsby"
import { Div, Text, Row, Col, Container, Image, Anchor, Tag } from "atomize"
import Loadable from "@loadable/component"

import messaging from "../../images/landing-screen-2.svg"

// import messaging from "../../images/tour.svg"

const ExpandMore = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/icons/ExpandMore")
)
const Accordion = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/Accordion")
)
const AccordionDetails = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/AccordionDetails")
)
const AccordionSummary = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/AccordionSummary")
)

const list = [
  {
    id: 1,
    frage:
      "Warum werden nicht alle Bundesländer mit allen Infos komplett unterstützt?",
    antwort: (
      <Text textSize="paragraph">
        Wir halten die Datenlage bezüglich Maßnahmen, Fallzahlen und bekannten
        Testzentren und Gesundheitsämtern kontinuierlich aktuell, um
        sicherzustellen, dass unsere Nutzer richtig informiert sind. Jedoch
        variiert die verfügbare Datenlage von Bundesland zu Bundesland sehr,
        sodass wir aktuell in Brandenburg, Mecklenburg-Vorpommern, Niedersachsen
        und Thüringen die Testzentren und Schwerpunktpraxen nicht angeben können
        - alle anderen Funktionalitäten sind deutschlandweit verfügbar. Falls
        Sie Informationen zu Testzentren oder Schwerpunktpraxen haben, die Sie
        mit uns teilen können, melden Sie sich gerne unter:{" "}
        <Anchor href="mailto:corona@fusionbase.com">
          corona@fusionbase.com
        </Anchor>
      </Text>
    ),
  },
  {
    id: 2,
    frage: "Was kann das Corona Navi?",
    antwort: (
      <Text textSize="paragraph">
        Das Corona Navi kann dir Informationen zu folgenden Fragen liefern: Wie
        ist die Lage in meiner Region? Welche Maßnahmen gelten bei mir? Ich
        fühle mich krank, habe ich Corona? Ich glaube ich habe Corona- was soll
        ich jetzt machen?{" "}
        {/* <Link to="/behoerden">
          <Anchor>Hier finden Sie weitere Informationen.</Anchor>
        </Link> */}
      </Text>
    ),
  },
  {
    id: 3,
    frage: "Warum bietet ihr das Corona Navi an?",
    antwort: (
      <Text textSize="paragraph">
        Wir sind davon überzeugt, dass der Schlüssel zum Umgang mit dieser
        Pandemie darin besteht, allen Menschen genaue, zuverlässige und
        relevante Informationen und Handlungsempfehlungen zur Verfügung zu
        stellen. Aktuell sind Informationen über das Internet verteilt, während
        die Verwaltungen mit einem beispiellosen Zustrom von Anfragen
        konfrontiert sind. Um dies zu überwinden, haben sich die Startups{" "}
        <Anchor
          href="https://convaise.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Convaise
        </Anchor>
        ,{" "}
        <Anchor
          href="https://www.fusionbase.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fusionbase
        </Anchor>{" "}
        und{" "}
        <Anchor
          href="https://www.behoerdenarzt.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          behördenarzt.de
        </Anchor>{" "}
        zusammengeschlossen, um mit dem Corona Navi eine zentrale Anlaufstelle
        für relevante Informationen zu Covid-19 zu schaffen. Unser Ziel ist es
        unseren Mitbürgern zu helfen und unsere Verwaltungen zu entlasten.
      </Text>
    ),
  },
  {
    id: 4,
    frage:
      "Ich bin von einer Behörde und würde gerne mit euch arbeiten, was kann ich tun?",
    antwort: (
      <Text textSize="paragraph">
        Melden Sie sich gerne unter{" "}
        <Anchor href="mailto:behoerde@convaise.com">
          behoerde@convaise.com
        </Anchor>
        ,{" "}
        <Link to="/behoerden" style={{ color: "#026DD6", fontWeight: "500" }}>
          auf dieser Seite
        </Link>{" "}
        finden Sie weitere Informationen zu den Möglichkeiten wie sich Behörden
        involvieren können.
      </Text>
    ),
  },
  {
    id: 5,
    frage:
      "Ich bin von einem Unternehmen, wie können wir Teil dieses Projektes werden?",
    antwort: (
      <Text textSize="paragraph">
        Melden Sie sich gerne unter{" "}
        <Anchor href="mailto:partner@convaise.com">partner@convaise.com</Anchor>
        .
      </Text>
    ),
  },
]

function About() {
  const [open, setOpen] = useState(0)

  function handleOpen(item) {
    if (item !== open) {
      setOpen(item)
    } else {
      setOpen(0)
    }
  }

  return (
    <Div tag="section" id="Lösung">
      <Container>
        <Div
          p={{ t: "4rem", b: "6rem" }}
          border={{ b: "1px solid" }}
          borderColor="gray300"
        >
          <Row>
            <Col size={{ xs: 12, md: 5, lg: 4 }} textAlign="center">
              <Image
                src={messaging}
                w={{ xs: "100%", sm: "100%", md: "25rem" }}
                m={{ y: { xs: "2rem", sm: "3rem", md: "auto" } }}
                maxW="25rem"
              />
            </Col>
            <Col size={{ xs: 12, md: 6, lg: 7 }} offset={{ xs: 0, md: 1 }}>
              <Div p={{ l: { lg: "1rem" } }}>
                <Text
                  textSize={{ xs: "title", sm: "heading", md: "display1" }}
                  textWeight="500"
                  fontFamily="secondary"
                  textAlign="left"
                  m={{ b: "1rem" }}
                >
                  Häufig gestellte Fragen
                </Text>
                <Tag
                  bg="success800"
                  textColor="white"
                  h="2rem"
                  // p={{ x: "1rem" }}
                  rounded="circle"
                  m={{ b: "2rem" }}
                >
                  #GemeinsamGegenCorona
                </Tag>
                <Div>
                  {list.map(element => {
                    return (
                      <Accordion
                        expanded={open === element.id}
                        onChange={() => handleOpen(element.id)}
                        key={element.id}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          // aria-controls="panel2bh-content"
                          id={`faq${element.id}`}
                        >
                          <Text textSize="paragraph" textWeight="500">
                            {element.frage}
                          </Text>
                        </AccordionSummary>
                        <AccordionDetails>{element.antwort}</AccordionDetails>
                      </Accordion>
                    )
                  })}
                </Div>
              </Div>
            </Col>
          </Row>
        </Div>
      </Container>
    </Div>
  )
}

export default About
